module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"EMSCULPT-POINT Privatpraxis ESTETIESE","short_name":"EMSCULPT-PNT","description":"Bei ESTETIESE/Bremen: Fettabbau(-30%) und Muskelaufbau(+25%), Body Shaping:Bauch(Sixpack), Gesäß(Po-Lift, „BRAZILIAN BUTT“), Arme, Oberschenkel und Waden ","start_url":"/","lang":"de","display":"standalone","icon":"static/favicons/favicon.png","background_color":"#666","theme_color":"#fff","icons":[{"src":"static/favicons/favicon_512px.png","sizes":"512x512","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_256px.png","sizes":"256x256","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_192px.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_127px.png","sizes":"127x127","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_96px.png","sizes":"96x96","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_48px.png","sizes":"48x48","type":"image/png","purpose":"any"},{"src":"static/favicons/favicon_192_mascable.png","sizes":"192x192","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bd988af44a9a1c741f461b431de3185d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
